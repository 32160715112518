import React, { useLayoutEffect, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useBreakpoint } from '@hooks/useBreakpoint'
import InteriorViewer3D from '../elements/CarViewer3d/InteriorViewer3D'

const Section = tw.section`relative flex items-center self-center min-h-[400px] h-[50vh] md:h-[70vh] justify-center xl:h-screen xl:max-h-screen mx-auto my-auto 
xl:px-[10vw] //if enableZoom={true} uncomment this
`
const ZoomControls = styled.div`
	${tw`w-[150px]  md:w-[203px] h-[44px] md:h-12 bg-superLight rounded-emf absolute z-[99] items-center flex top-[-50px] left-[20px] md:left-[10%] font-futuraBold justify-center`}
`
const ZoomButton = styled.div`
	${tw`ease-in-out cursor-pointer`}
	translate: scale 0.1s;
`

const CarinteriorpreviewBlock = () => {
	const [hoverState, setHoverState] = useState(false)
	const { isTablet } = useBreakpoint()
	// handling outside click on mobile
	const toggleHoverState = () => setHoverState((currentState) => !currentState)

	const minusRef = useRef()
	const plusRef = useRef()
	const [minusHold, setMinusHold] = useState(false)
	const [plusHold, setPlusHold] = useState(false)
	const [minusClick, setMinusClick] = useState(false)
	const [plusClick, setPlusClick] = useState(false)

	const toggleMinusClick = () => setMinusClick((curr) => !curr)
	const togglePlusClick = () => setPlusClick((curr) => !curr)
	const toggleMinusHold = () => setMinusHold((curr) => !curr)
	const togglePlusHold = () => setPlusHold((curr) => !curr)

	// Timeout, started on mousedown, triggers the beginning of a hold
	let holdStarter = null
	// Milliseconds to wait before recognizing a hold
	let holdDelay = 200
	// Indicates the user is currently holding the mouse down
	let holdActive = false
	const onMinusMouseDown = () => {
		holdStarter = setTimeout(function () {
			holdStarter = null
			holdActive = true
			toggleMinusHold()
		}, holdDelay)
	}
	const onMinusMouseUp = () => {
		if (holdStarter) {
			clearTimeout(holdStarter)
			toggleMinusClick()
		} else if (holdActive) {
			holdActive = false
			toggleMinusHold()
		}
	}
	const onPlusMouseDown = () => {
		holdStarter = setTimeout(function () {
			holdStarter = null
			holdActive = true
			togglePlusHold()
		}, holdDelay)
	}
	const onPlusMouseUp = () => {
		if (holdStarter) {
			clearTimeout(holdStarter)
			togglePlusClick()
		} else if (holdActive) {
			holdActive = false
			togglePlusHold()
		}
	}

	useLayoutEffect(() => {
		if (isTablet) {
			minusRef.current.addEventListener('click', toggleMinusClick)
			plusRef.current.addEventListener('click', togglePlusClick)
		}

		if (!isTablet) {
			minusRef.current.addEventListener('mouseup', onMinusMouseUp)
			minusRef.current.addEventListener('mousedown', onMinusMouseDown)
			plusRef.current.addEventListener('mouseup', onPlusMouseUp)
			plusRef.current.addEventListener('mousedown', onPlusMouseDown)
		}

		return () => {
			if (isTablet) {
				minusRef.current.removeEventListener('click', toggleMinusClick)
				plusRef.current.removeEventListener('click', togglePlusClick)
			}

			if (!isTablet) {
				minusRef.current.removeEventListener('mouseup', onMinusMouseUp)
				minusRef.current.removeEventListener('mousedown', onMinusMouseDown)
				plusRef.current.removeEventListener('mouseup', onPlusMouseUp)
				plusRef.current.removeEventListener('mousedown', onPlusMouseDown)
			}
		}
	}, [])

	return (
		<Section id='car-interior' className='pb-[160px] md:emf-pb-spacing'>
			<ZoomControls className='flex items-center px-3 py-3'>
				<ZoomButton ref={minusRef} className={`${!!minusHold && 'scale-[1.12]'}`}>
					<svg version='1.1' viewBox='0 0 455 455' height='30' width='30'>
						<rect y='212.5' width='455' height='30' />
					</svg>
				</ZoomButton>
				<div className='px-3 rotate-90 md:px-6'>
					<svg version='1.1' viewBox='0 0 455 455' height='30' width='30'>
						<rect y='212.5' width='455' height='30' fill='#CCCCCC' />
					</svg>
				</div>
				<ZoomButton ref={plusRef} className={`${!!plusHold && 'scale-[1.12]'}`}>
					<svg version='1.1' viewBox='0 0 455 455' height='30' width='30'>
						<polygon points='455 212.5 242.5 212.5 242.5 0 212.5 0 212.5 212.5 0 212.5 0 242.5 212.5 242.5 212.5 455 242.5 455 242.5 242.5 455 242.5' />
					</svg>
				</ZoomButton>
			</ZoomControls>
			<InteriorViewer3D
				hoverState={hoverState}
				setHoverState={setHoverState}
				toggleHoverState={toggleHoverState}
				plusHold={plusHold}
				minusHold={minusHold}
				plusClick={plusClick}
				minusClick={minusClick}
				togglePlusClick={togglePlusClick}
				toggleMinusClick={toggleMinusClick}
			/>
		</Section>
	)
}
export default CarinteriorpreviewBlock

import React, { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Environment, Plane, OrbitControls } from '@react-three/drei'
import { Suspense } from 'react'
import Model from './Model'
import { MathUtils, sRGBEncoding, Vector3 } from 'three'
import Loader from '../Loader'
import { useBreakpoint } from '@hooks/useBreakpoint'

const Scene = ({ hoverState, setHoverState, setRotationOn, setRotationOff, toggleHoverState }) => {
	// Set receiveShadow on any mesh that should be in shadow,
	// and castShadow on any mesh that should create a shadow.
	// <Environment preset="sunset" /> on different preset values changes the shader reflections on the car completely

	return (
		<group onClick={() => toggleHoverState(null)} onPointerEnter={setRotationOff} onPointerLeave={setRotationOn}>
			<Model receiveShadow castShadow hoverState={hoverState} setHoverState={setHoverState} setRotationOn={setRotationOn} setRotationOff={setRotationOff} toggleHoverState={toggleHoverState} />
			<Environment
				//image background
				background={false}
				files={'./potsdamer_platz_1k.hdr'}
				path={'/'}
			/>
			<Plane receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]} args={[512, 512]}>
				<meshStandardMaterial attach='material' color='white' />
				<shadowMaterial attach='material' opacity={0.05} />
			</Plane>
		</group>
	)
}
const SpotLights = ({ position = new Vector3(2, 3, 2), castShadow, intensity = 0.75, angle = 10, ...props }) => {
	const light = useRef()
	// useHelper(light, SpotLightHelper, 'red') //helper function marking light source
	return <spotLight ref={light} distance={2000} castShadow={!!castShadow} position={position} intensity={intensity} angle={angle} {...props} />
}

const Lights = ({ position = new Vector3(1, 3, 2), castShadow, intensity = 0.5, angle = 10, ...props }) => {
	const light = useRef()
	// useHelper(light, DirectionalLightHelper, 'red') //helper function marking light source
	return <directionalLight ref={light} castShadow={!!castShadow} position={position} intensity={intensity} {...props} shadow-mapSize-width={1024} shadow-mapSize-height={1204} />
}

const CarViewer3D = ({ hoverState, setHoverState, toggleHoverState, minusHold, plusHold, minusClick, plusClick, togglePlusClick, toggleMinusClick }) => {
	const browser = typeof window !== 'undefined'
	const { isTablet } = useBreakpoint()
	const getCameraZoom = () => {
		if (!!isTablet) return 1
		return 1.15
	}
	const Zoom = () => {
		return useFrame(({ camera }) => {
			if (camera.zoom > 0.61 && minusHold) {
				camera.zoom = MathUtils.lerp(camera.zoom, (camera.zoom -= 0.15), 0.1)
				camera.updateProjectionMatrix()
			}
			if (camera.zoom > 0.61 && minusClick) {
				toggleMinusClick()
				camera.zoom = MathUtils.lerp(camera.zoom, (camera.zoom -= 0.75), 0.1)
				camera.updateProjectionMatrix()
			}
			if (camera.zoom < 1.5 && plusClick) {
				togglePlusClick()
				camera.zoom = MathUtils.lerp(camera.zoom, (camera.zoom += 0.75), 0.1)
				camera.updateProjectionMatrix()
			}
			if (camera.zoom < 1.5 && plusHold) {
				camera.zoom = MathUtils.lerp(camera.zoom, (camera.zoom += 0.15), 0.1)
				camera.updateProjectionMatrix()
			}
		})
	}
	const [isObjectRotating, setIsObjectRotating] = useState(true)
	const setRotationOn = () => {
		!isObjectRotating && setIsObjectRotating(true)
	}
	const setRotationOff = () => {
		!!isObjectRotating && setIsObjectRotating(false)
	}
	const [mouseHold, setMouseHold] = useState(false)
	const toggleRotation = () => setMouseHold((curr) => !curr)
	useEffect(() => {
		window.addEventListener('mouseup', toggleRotation)
		window.addEventListener('mousedown', toggleRotation)
		return () => {
			window.removeEventListener('mouseup', toggleRotation)
			window.removeEventListener('mousedown', toggleRotation)
		}
	}, [])

	if (browser) {
		return (
			<Suspense fallback={<Loader />}>
				<Canvas shadows dpr={[1, 2]} camera={{ position: [getCameraZoom(), 1.1, 4], fov: 60 }} style={{ cursor: 'grab' }} gl={{ antialias: true, outputEncoding: sRGBEncoding }}>
					<OrbitControls
						enablePan={false}
						maxPolarAngle={Math.PI * 0.48}
						autoRotate={mouseHold || isObjectRotating}
						enableDamping={true}
						autoRotateSpeed={0.45}
						enableZoom={false} //mousewheel zoom
						// minDistance={3}
						// maxDistance={8}
					/>
					<Zoom />
					{/* <OrbitControls enablePan={false} maxPolarAngle={Math.PI * 0.48} minDistance={3} maxDistance={8} autoRotate={false} enableDamping={true} autoRotateSpeed={0.65} /> */}
					<fog attach='fog' args={['white', 0, 50]} />
					<Lights castShadow={false} />
					<Lights position={new Vector3(-1.75, 3.75, 2.5)} intensity={0.55} castShadow={true} />
					<Lights position={new Vector3(1.25, 4.75, -1)} castShadow={true} angle={5} />
					<Scene hoverState={hoverState} setHoverState={setHoverState} setRotationOn={setRotationOn} setRotationOff={setRotationOff} toggleHoverState={toggleHoverState} />
				</Canvas>
			</Suspense>
		)
	}
	return null
}

export default CarViewer3D

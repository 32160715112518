import React, { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { Environment, Plane, OrbitControls } from '@react-three/drei'
import { Suspense } from 'react'
import Model from './Model'
import { BackSide, BufferGeometry, ImageUtils, LinearEncoding, MathUtils, Mesh, MeshBasicMaterial, SphereGeometry, sRGBEncoding, TextureLoader, Vector3 } from 'three'
import Loader from '../Loader'
import { useBreakpoint } from '@hooks/useBreakpoint'

const Scene = () => {
	const texture = useLoader(TextureLoader, '/Emil_Frey_Lambo_GT3_tiny.jpg')

	return (
		<mesh>
			<sphereBufferGeometry attach='geometry' args={[500, 60, 40]} />
			<meshBasicMaterial attach='material' map={texture} side={BackSide} />
		</mesh>
	)
}
const SpotLights = ({ position = new Vector3(2, 3, 2), castShadow, intensity = 0.75, angle = 10, ...props }) => {
	const light = useRef()
	// useHelper(light, SpotLightHelper, 'red') //helper function marking light source
	return <spotLight ref={light} distance={2000} castShadow={!!castShadow} position={position} intensity={intensity} angle={angle} {...props} />
}

const Lights = ({ position = new Vector3(1, 3, 2), castShadow, intensity = 0.5, angle = 10, ...props }) => {
	const light = useRef()
	// useHelper(light, DirectionalLightHelper, 'red') //helper function marking light source
	return <directionalLight ref={light} castShadow={!!castShadow} position={position} intensity={intensity} {...props} shadow-mapSize-width={1024} shadow-mapSize-height={1204} />
}

const InteriorViewer3D = ({ minusHold, plusHold, minusClick, plusClick, togglePlusClick, toggleMinusClick }) => {
	const browser = typeof window !== 'undefined'
	const { isTablet } = useBreakpoint()
	const Zoom = () => {
		return useFrame(({ camera }) => {
			if (camera.zoom > 0.61 && minusHold) {
				camera.zoom = MathUtils.lerp(camera.zoom, (camera.zoom -= 0.15), 0.1)
				camera.updateProjectionMatrix()
			}
			if (camera.zoom > 0.61 && minusClick) {
				toggleMinusClick()
				camera.zoom = MathUtils.lerp(camera.zoom, (camera.zoom -= 0.75), 0.1)
				camera.updateProjectionMatrix()
			}
			if (camera.zoom < 1.5 && plusClick) {
				togglePlusClick()
				camera.zoom = MathUtils.lerp(camera.zoom, (camera.zoom += 0.75), 0.1)
				camera.updateProjectionMatrix()
			}
			if (camera.zoom < 1.5 && plusHold) {
				camera.zoom = MathUtils.lerp(camera.zoom, (camera.zoom += 0.15), 0.1)
				camera.updateProjectionMatrix()
			}
		})
	}
	//LinearEncoding gives brighter picture
	if (browser) {
		return (
			<Suspense fallback={<Loader />}>
				<Canvas shadows dpr={[1, 2]} camera={{ position: [1.1, 1.1, 4], fov: 55 }} style={{ cursor: 'grab' }} gl={{ antialias: true, outputEncoding: LinearEncoding }}>
					<OrbitControls
						enablePan={false}
						maxPolarAngle={Math.PI * 0.55}
						minPolarAngle={Math.PI * 0.28}
						minAzimuthAngle={-Math.PI * 0.75}
						maxAzimuthAngle={-Math.PI * 0.25}
						enableDamping={true}
						autoRotateSpeed={0.45}
						reverseOrbit={isTablet}
						enableZoom={false} //mousewheel zoom
						target={[100, 0, 0]}
					/>
					<Zoom />
					<Scene />
				</Canvas>
			</Suspense>
		)
	}
	return null
}

export default InteriorViewer3D

import React from 'react'
import { motion } from 'framer-motion'

const loadingContainer = {
	width: '10rem',
	height: '10rem',
	display: 'flex',
	justifyContent: 'space-around',
}

const loadingRect = {
	display: 'block',
	width: '2.5rem',
	height: '2.5rem',
	backgroundColor: 'black',
	borderRadius: '0.15rem',
}

const loadingContainerVariants = {
	start: {
		transition: {
			staggerChildren: 0.2,
		},
	},
	end: {
		transition: {
			staggerChildren: 0.2,
		},
	},
}

const loadingRectVariants = {
	start: {
		y: '50%',
	},
	end: {
		y: '150%',
	},
}

const loadingRectTransition = {
	duration: 0.6,
	yoyo: Infinity,
	ease: 'easeInOut',
}

export default function Loading() {
	return (
		<motion.div style={loadingContainer} variants={loadingContainerVariants} initial='start' animate='end'>
			<motion.span style={loadingRect} variants={loadingRectVariants} transition={loadingRectTransition} />
			<motion.span style={loadingRect} variants={loadingRectVariants} transition={loadingRectTransition} />
			<motion.span style={loadingRect} variants={loadingRectVariants} transition={loadingRectTransition} />
		</motion.div>
	)
}

import React, { useLayoutEffect, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import CarViewer3D from '../elements/CarViewer3d/CarViewer3D'
import parse from 'html-react-parser'
import { AnimatePresence, motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import { useBreakpoint } from '@hooks/useBreakpoint'
import CarinteriorpreviewBlock from './CarinteriorpreviewBlock'

const fadeIn = {
	hidden: {
		x: '15vh',
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
		transition: {
			type: 'tween',
			ease: 'easeInOut',
			duration: 0.25,
			delay: 0.1,
		},
	},
	exit: {
		x: '15vh',
		opacity: 0,
		transition: {
			type: 'tween',
			ease: 'easeInOut',
			duration: 0.25,
		},
	},
}

export const overlayVariants = {
	initial: '0',
	first: '1',
	second: '2',
	third: '3',
	fourth: '4',
	fifth: '5',
	sixth: '6',
	seventh: '7',
}

const Section = tw.section`relative flex items-center self-center min-h-[400px] h-[50vh] md:h-[70vh] justify-center xl:h-screen xl:max-h-screen mx-auto my-auto 
xl:px-[10vw] //if enableZoom={true} uncomment this
`
const OverlayWrap = styled(motion.div)`
	${tw`h-auto xs:max-w-[403px] mx-8 lg:min-h-[100px] bg-superLight rounded-emf absolute z-[99] text-left items-center flex`}

	bottom: 15%;

	@media screen and (min-width: 1024px) {
		bottom: ${({ hoverState }) => {
			if (hoverState === overlayVariants.fourth || hoverState === overlayVariants.initial) {
				return '40%'
			}
			return '45%'
		}};
		right: ${({ hoverState }) => {
			if (hoverState === overlayVariants.third || hoverState === overlayVariants.initial || hoverState === overlayVariants.fourth) {
				return '3%'
			}
			return '8%'
		}};
	}

	@media screen and (min-width: 1800px) {
		right: ${({ hoverState }) => {
			if (hoverState === overlayVariants.initial) {
				return '7%'
			}
			return '12%'
		}};
	}
`
const ZoomControls = styled.div`
	${tw`w-[150px]  md:w-[203px] h-[44px] md:h-12 bg-superLight rounded-emf absolute z-[99] items-center flex top-[-50px] left-[20px] md:left-[10%] font-futuraBold justify-center`}
`
const ZoomButton = styled.div`
	${tw`ease-in-out cursor-pointer`}
	translate: scale 0.1s;
`
const StyledDescription = tw.p`text-base md:text-xl font-futuraBook text-black`
const StyledHeadline = tw.p`text-lg md:text-xl font-futuraBold text-black pb-3 `

const WpAcfCarpreviewBlock = ({ data }) => {
	const { anchor } = data.attributes
	const module = data.modulesCarpreview
	const { doorDriverSide, doorPassengerSide, frontWheelDriverSide, fuelCap, rearWheelDriverSide, roof, spoiler, initial } = module
	const [hoverState, setHoverState] = useState(overlayVariants.initial)
	const { isTablet } = useBreakpoint()
	const getOverlayMessage = () => {
		switch (hoverState) {
			case overlayVariants.initial:
				return initial
			case overlayVariants.first:
				return doorDriverSide
			case overlayVariants.second:
				return doorPassengerSide
			case overlayVariants.third:
				return frontWheelDriverSide
			case overlayVariants.fourth:
				return rearWheelDriverSide
			case overlayVariants.fifth:
				return fuelCap
			case overlayVariants.sixth:
				return roof
			case overlayVariants.seventh:
				return spoiler
			case null:
				return null
			default:
				return null
		}
	}
	// handling outside click on mobile
	const toggleHoverState = (value) => {
		if (!hoverState && !!value) {
			setHoverState(value)
		}
		if (!!hoverState) {
			setHoverState(null)
		}
	}

	const minusRef = useRef()
	const plusRef = useRef()
	const [minusHold, setMinusHold] = useState(false)
	const [plusHold, setPlusHold] = useState(false)
	const [minusClick, setMinusClick] = useState(false)
	const [plusClick, setPlusClick] = useState(false)

	const toggleMinusClick = () => setMinusClick((curr) => !curr)
	const togglePlusClick = () => setPlusClick((curr) => !curr)
	const toggleMinusHold = () => setMinusHold((curr) => !curr)
	const togglePlusHold = () => setPlusHold((curr) => !curr)

	// Timeout, started on mousedown, triggers the beginning of a hold
	let holdStarter = null
	// Milliseconds to wait before recognizing a hold
	let holdDelay = 200
	// Indicates the user is currently holding the mouse down
	let holdActive = false
	const onMinusMouseDown = () => {
		holdStarter = setTimeout(function () {
			holdStarter = null
			holdActive = true
			toggleMinusHold()
		}, holdDelay)
	}
	const onMinusMouseUp = () => {
		if (holdStarter) {
			clearTimeout(holdStarter)
			toggleMinusClick()
		} else if (holdActive) {
			holdActive = false
			toggleMinusHold()
		}
	}
	const onPlusMouseDown = () => {
		holdStarter = setTimeout(function () {
			holdStarter = null
			holdActive = true
			togglePlusHold()
		}, holdDelay)
	}
	const onPlusMouseUp = () => {
		if (holdStarter) {
			clearTimeout(holdStarter)
			togglePlusClick()
		} else if (holdActive) {
			holdActive = false
			togglePlusHold()
		}
	}

	useLayoutEffect(() => {
		if (isTablet) {
			minusRef.current.addEventListener('click', toggleMinusClick)
			plusRef.current.addEventListener('click', togglePlusClick)
		}

		if (!isTablet) {
			minusRef.current.addEventListener('mouseup', onMinusMouseUp)
			minusRef.current.addEventListener('mousedown', onMinusMouseDown)
			plusRef.current.addEventListener('mouseup', onPlusMouseUp)
			plusRef.current.addEventListener('mousedown', onPlusMouseDown)
		}

		return () => {
			if (isTablet) {
				minusRef.current.removeEventListener('click', toggleMinusClick)
				plusRef.current.removeEventListener('click', togglePlusClick)
			}

			if (!isTablet) {
				minusRef.current.removeEventListener('mouseup', onMinusMouseUp)
				minusRef.current.removeEventListener('mousedown', onMinusMouseDown)
				plusRef.current.removeEventListener('mouseup', onPlusMouseUp)
				plusRef.current.removeEventListener('mousedown', onPlusMouseDown)
			}
		}
	}, [])

	return (
		<>
			<Section {...(anchor !== '' ? { id: anchor } : {})} className='pb-[160px] md:emf-pb-spacing'>
				<ZoomControls className='flex items-center px-3 py-3'>
					<ZoomButton ref={minusRef} className={`${!!minusHold && 'scale-[1.12]'}`}>
						<svg version='1.1' viewBox='0 0 455 455' height='30' width='30'>
							<rect y='212.5' width='455' height='30' />
						</svg>
					</ZoomButton>
					<div className='px-3 rotate-90 md:px-6'>
						<svg version='1.1' viewBox='0 0 455 455' height='30' width='30'>
							<rect y='212.5' width='455' height='30' fill='#CCCCCC' />
						</svg>
					</div>
					<ZoomButton ref={plusRef} className={`${!!plusHold && 'scale-[1.12]'}`}>
						<svg version='1.1' viewBox='0 0 455 455' height='30' width='30'>
							<polygon points='455 212.5 242.5 212.5 242.5 0 212.5 0 212.5 212.5 0 212.5 0 242.5 212.5 242.5 212.5 455 242.5 455 242.5 242.5 455 242.5' />
						</svg>
					</ZoomButton>
				</ZoomControls>

				<AnimatePresence exitBeforeEnter initial={true}>
					{!!hoverState && (
						<OverlayWrap key='overlay-wrap' hoverState={hoverState} variants={fadeIn} initial='hidden' animate={!!hoverState ? 'visible' : 'hidden'} exit='exit'>
							<div className='flex items-center py-3 pl-6 pr-3 md:p-6 lg:p-12'>
								{hoverState === overlayVariants.initial && <StaticImage className='w-10 h-10 mb-3 mr-3 min-w-[40px]' src='../../images/info_cyllinder.png' alt='' />}
								<StyledDescription>{parse(getOverlayMessage())}</StyledDescription>
							</div>
						</OverlayWrap>
					)}
				</AnimatePresence>

				<CarViewer3D
					hoverState={hoverState}
					setHoverState={setHoverState}
					toggleHoverState={toggleHoverState}
					plusHold={plusHold}
					minusHold={minusHold}
					plusClick={plusClick}
					minusClick={minusClick}
					togglePlusClick={togglePlusClick}
					toggleMinusClick={toggleMinusClick}
				/>
			</Section>
			<CarinteriorpreviewBlock />
		</>
	)
}
export default WpAcfCarpreviewBlock

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, Text } from '@react-three/drei'
import { overlayVariants } from '../../modules/WpAcfCarpreviewBlock'
// import { MeshPhongMaterial } from 'three'
// import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
// import { extend } from '@react-three/fiber'
// import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
// // import myFont from './Roboto_Medium_Regular.json'
// extend({ TextGeometry })
import { useBreakpoint } from '@hooks/useBreakpoint'

export default function Model({ hoverState, setHoverState, setRotationOn, setRotationOff, toggleHoverState, ...props }) {
	const group = useRef()
	const { nodes, materials } = useGLTF('/car/model.gltf')
	const { isTablet } = useBreakpoint()
	const { first, second, third, fourth, fifth, sixth, seventh } = overlayVariants
	const stopPropagation = (e) => e.stopPropagation()
	const hoverColor = '#0053A1'
	const color = '#3375b4'

	// const Text3d = ({ color = 'white', size = 0.09, ...props }) => {
	// 	const font = new FontLoader().parse(myFont)
	// 	const textOptions = { font, size, height: 0.02 }
	// 	return (
	// 		<mesh {...props}>
	// 			<textGeometry attach='geometry' args={['i', textOptions]} center />
	// 			<meshLambertMaterial attach='material' color={color} />
	// 		</mesh>
	// 	)
	// }

	return (
		<group ref={group} {...props} dispose={null}>
			{
				//door 1 side
			}
			<mesh
				position={[1, 0.56, -0.15]}
				rotation={[0, 0, -1.3]}
				onClick={(e) => {
					stopPropagation(e)
					setHoverState(first)
				}}
				onPointerOver={() => setHoverState(first)}
				onPointerOut={() => setHoverState(null)}
			>
				<cylinderGeometry attach='geometry' args={[0.08, 0.08, 0.03, 16]} />
				<Text color='white' anchorX='center' anchorY='middle' rotation={[-1.57, 0, 1.57]} position={[0, 0.02, 0]} outlineWidth={'3%'} fontSize={0.14}>
					i
				</Text>
				<meshStandardMaterial attach='material' color={hoverState === first ? hoverColor : color} roughness={0.7} metalness={0.6} />
			</mesh>
			{
				//door 1 side mobile invisible area for better UX & clickability
			}
			{isTablet && (
				<mesh
					position={[1, 0.56, -0.15]}
					rotation={[0, 0, -1.3]}
					onClick={(e) => {
						stopPropagation(e)
						setHoverState(first)
					}}
					onPointerOver={() => setHoverState(first)}
					onPointerOut={() => setHoverState(null)}
				>
					<cylinderGeometry attach='geometry' args={[0.5, 0.5, 0.03, 16]} />
					<meshStandardMaterial attach='material' transparent={true} opacity={0} />
				</mesh>
			)}
			{
				//door 2 side
			}
			<mesh
				position={[-1, 0.56, 0.09]}
				rotation={[0, 0, 1.3]}
				onClick={(e) => {
					stopPropagation(e)
					setHoverState(second)
				}}
				onPointerOver={() => setHoverState(second)}
				onPointerOut={() => setHoverState(null)}
			>
				<cylinderGeometry attach='geometry' args={[0.08, 0.08, 0.03, 16]} />
				<Text color='white' anchorX='center' anchorY='middle' rotation={[-1.57, 0, -1.57]} position={[0, 0.02, 0]} outlineWidth={'3%'} fontSize={0.14}>
					i
				</Text>
				<meshStandardMaterial attach='material' color={hoverState === second ? hoverColor : color} roughness={0.7} metalness={0.6} />
			</mesh>
			{
				//door 2 side mobile invisible area for better UX & clickability
			}
			{isTablet && (
				<mesh
					position={[-1, 0.56, 0.09]}
					rotation={[0, 0, 1.3]}
					onClick={(e) => {
						stopPropagation(e)
						setHoverState(second)
					}}
					onPointerOver={() => setHoverState(second)}
					onPointerOut={() => setHoverState(null)}
				>
					<cylinderGeometry attach='geometry' args={[0.5, 0.5, 0.03, 16]} />
					<meshStandardMaterial attach='material' transparent={true} opacity={0} />
				</mesh>
			)}
			{
				//front wheel (brake) 1 side
			}
			<mesh
				position={[1.06, 0.25, 1.18]}
				rotation={[0, 0, -1.5]}
				onClick={(e) => {
					stopPropagation(e)
					setHoverState(third)
				}}
				onPointerOver={() => setHoverState(third)}
				onPointerOut={() => setHoverState(null)}
			>
				<cylinderGeometry attach='geometry' args={[0.08, 0.08, 0.03, 16]} />
				<Text color='white' anchorX='center' anchorY='middle' rotation={[-1.57, 0, 1.57]} position={[0, 0.02, 0]} outlineWidth={'3%'} fontSize={0.14}>
					i
				</Text>
				<meshStandardMaterial attach='material' color={hoverState === third ? hoverColor : color} roughness={0.7} metalness={0.6} opacity={hoverState === third ? 0.95 : 0.8} transparent={true} />
			</mesh>
			{
				//front wheel (brake) 1 side mobile invisible area for better UX & clickability
			}
			{isTablet && (
				<mesh
					position={[1.06, 0.25, 1.18]}
					rotation={[0, 0, -1.5]}
					onClick={(e) => {
						stopPropagation(e)
						setHoverState(third)
					}}
					onPointerOver={() => setHoverState(third)}
					onPointerOut={() => setHoverState(null)}
				>
					<cylinderGeometry attach='geometry' args={[0.5, 0.5, 0.03, 16]} />
					<meshStandardMaterial attach='material' transparent={true} opacity={0} />
				</mesh>
			)}
			{
				//rear wheel (tyre) 1 side
			}
			<mesh
				position={[1.09, 0.55, -1.53]}
				rotation={[0, 0, -1.5]}
				onClick={(e) => {
					stopPropagation(e)
					setHoverState(fourth)
				}}
				onPointerOver={() => setHoverState(fourth)}
				onPointerOut={() => setHoverState(null)}
			>
				<cylinderGeometry attach='geometry' args={[0.08, 0.08, 0.03, 16]} />
				<Text color='white' anchorX='center' anchorY='middle' rotation={[-1.57, 0, 1.57]} position={[0, 0.02, 0]} outlineWidth={'3%'} fontSize={0.14}>
					i
				</Text>
				<meshStandardMaterial attach='material' color={hoverState === fourth ? hoverColor : color} roughness={0.7} metalness={0.6} opacity={hoverState === fourth ? 0.95 : 0.8} transparent={true} />
			</mesh>
			{
				//rear wheel (tyre) 1 side mobile invisible area for better UX & clickability
			}
			{isTablet && (
				<mesh
					position={[1.09, 0.55, -1.53]}
					rotation={[0, 0, -1.5]}
					onClick={(e) => {
						stopPropagation(e)
						setHoverState(fourth)
					}}
					onPointerOver={() => setHoverState(fourth)}
					onPointerOut={() => setHoverState(null)}
				>
					<cylinderGeometry attach='geometry' args={[0.5, 0.5, 0.03, 16]} />
					<meshStandardMaterial attach='material' transparent={true} opacity={0} />
				</mesh>
			)}
			{
				//fuel cap 2 side
			}
			<mesh
				position={[-0.88, 0.84, -0.91]}
				rotation={[0.3, 0, 0.9]}
				onClick={(e) => {
					stopPropagation(e)
					setHoverState(fifth)
				}}
				onPointerOver={() => setHoverState(fifth)}
				onPointerOut={() => setHoverState(null)}
			>
				<cylinderGeometry attach='geometry' args={[0.08, 0.08, 0.03, 16]} />
				<Text color='white' anchorX='center' anchorY='middle' rotation={[-1.57, 0, -1.57]} position={[0, 0.02, 0]} outlineWidth={'3%'} fontSize={0.14}>
					i
				</Text>
				<meshStandardMaterial attach='material' color={hoverState === fifth ? hoverColor : color} roughness={0.7} metalness={0.6} opacity={hoverState === fifth ? 0.95 : 0.8} transparent={true} />
			</mesh>
			{
				//fuel cap 2 side mobile invisible area for better UX & clickability
			}
			{isTablet && (
				<mesh
					position={[-0.88, 0.84, -0.91]}
					rotation={[0.3, 0, 0.9]}
					onClick={(e) => {
						stopPropagation(e)
						setHoverState(fifth)
					}}
					onPointerOver={() => setHoverState(fifth)}
					onPointerOut={() => setHoverState(null)}
				>
					<cylinderGeometry attach='geometry' args={[0.5, 0.5, 0.03, 16]} />
					<meshStandardMaterial attach='material' transparent={true} opacity={0} />
				</mesh>
			)}
			{
				//roof
			}
			<mesh
				position={[0, 1.15, 0]}
				rotation={[0, 0, 0]}
				onClick={(e) => {
					stopPropagation(e)
					setHoverState(sixth)
				}}
				onPointerOver={() => setHoverState(sixth)}
				onPointerOut={() => setHoverState(null)}
			>
				<cylinderGeometry attach='geometry' args={[0.08, 0.08, 0.03, 16]} />
				<Text color='white' anchorX='center' anchorY='middle' rotation={[-1.57, 0, 0]} position={[0, 0.02, 0]} outlineWidth={'3%'} fontSize={0.14}>
					i
				</Text>
				<meshStandardMaterial attach='material' color={hoverState === sixth ? hoverColor : color} roughness={0.7} metalness={0.6} opacity={hoverState === sixth ? 0.95 : 0.8} transparent={true} />
			</mesh>
			{
				//roof mobile invisible area for better UX & clickability
			}
			{isTablet && (
				<mesh
					position={[0, 1.15, 0]}
					rotation={[0, 0, 0]}
					onClick={(e) => {
						stopPropagation(e)
						setHoverState(sixth)
					}}
					onPointerOver={() => setHoverState(sixth)}
					onPointerOut={() => setHoverState(null)}
				>
					<cylinderGeometry attach='geometry' args={[0.5, 0.5, 0.03, 16]} />
					<meshStandardMaterial attach='material' transparent={true} opacity={0} />
				</mesh>
			)}
			{
				//spoiler
			}
			<mesh
				position={[0, 1.17, -2.15]}
				rotation={[0, 0, 0]}
				onClick={(e) => {
					stopPropagation(e)
					setHoverState(seventh)
				}}
				onPointerOver={() => setHoverState(seventh)}
				onPointerOut={() => setHoverState(null)}
			>
				<cylinderGeometry attach='geometry' args={[0.08, 0.08, 0.03, 16]} />
				<Text color='white' anchorX='center' anchorY='middle' rotation={[-1.57, 0, Math.PI]} position={[0, 0.02, 0]} outlineWidth={'3%'} fontSize={0.14}>
					i
				</Text>
				<meshStandardMaterial attach='material' color={hoverState === seventh ? hoverColor : color} roughness={0.7} metalness={0.6} opacity={hoverState === seventh ? 0.95 : 0.8} transparent={true} />
			</mesh>
			{
				//spoiler mobile invisible area for better UX & clickability
			}
			{isTablet && (
				<mesh
					position={[0, 1.17, -2.15]}
					rotation={[0, 0, 0]}
					onClick={(e) => {
						stopPropagation(e)
						setHoverState(seventh)
					}}
					onPointerOver={() => setHoverState(seventh)}
					onPointerOut={() => setHoverState(null)}
				>
					<cylinderGeometry attach='geometry' args={[0.5, 0.5, 0.03, 16]} />
					<meshStandardMaterial attach='material' transparent={true} opacity={0} />
				</mesh>
			)}
			<mesh castShadow receiveShadow geometry={nodes.carbon_diffusor_fin.geometry} material={nodes.carbon_diffusor_fin.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_diffusor_top_part.geometry} material={nodes.carbon_diffusor_top_part.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_fender_flaps.geometry} material={nodes.carbon_fender_flaps.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_hood_outake_air.geometry} material={nodes.carbon_hood_outake_air.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_rear_bumper_lower.geometry} material={nodes.carbon_rear_bumper_lower.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_rear_side_fin.geometry} material={nodes.carbon_rear_side_fin.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_rear_window.geometry} material={nodes.carbon_rear_window.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_side_intake_window.geometry} material={nodes.carbon_side_intake_window.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_side_intakes.geometry} material={nodes.carbon_side_intakes.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_side_win_behind.geometry} material={nodes.carbon_side_win_behind.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_sill_quad.geometry} material={nodes.carbon_sill_quad.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_splitter.geometry} material={nodes.carbon_splitter.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_spoiler_arms.geometry} material={nodes.carbon_spoiler_arms.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_spoiler_pipe.geometry} material={nodes.carbon_spoiler_pipe.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_spoiler_sideplate_inside.geometry} material={nodes.carbon_spoiler_sideplate_inside.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_wheelbase_door_front.geometry} material={nodes.carbon_wheelbase_door_front.material} />
			<mesh castShadow receiveShadow geometry={nodes.carbon_window_frame_inkl_side_win.geometry} material={nodes.carbon_window_frame_inkl_side_win.material} />
			<mesh castShadow receiveShadow geometry={nodes.door_L_carbon_mirror_arm.geometry} material={nodes.door_L_carbon_mirror_arm.material} position={[0, -0.05, 0]} />
			<mesh castShadow receiveShadow geometry={nodes.door_R_carbon_mirror_arm.geometry} material={nodes.door_R_carbon_mirror_arm.material} position={[0, -0.05, 0]} />
			<mesh castShadow receiveShadow geometry={nodes.body_fender_front.geometry} material={nodes.body_fender_front.material} />
			<mesh castShadow receiveShadow geometry={nodes.Plane240.geometry} material={nodes.Plane240.material} />
			<mesh castShadow receiveShadow geometry={nodes.Plane240_1.geometry} material={nodes.Plane240_1.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_fuel_L.geometry} material={nodes.body_fuel_L.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_fuel_R.geometry} material={nodes.body_fuel_R.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_hood.geometry} material={nodes.body_hood.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_mirror.geometry} material={nodes.body_mirror.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_mirror001.geometry} material={nodes.body_mirror001.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_rear_bumper_mid_small.geometry} material={nodes.body_rear_bumper_mid_small.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_rear_bumper_midpart.geometry} material={nodes.body_rear_bumper_midpart.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_rear_bumper_top.geometry} material={nodes.body_rear_bumper_top.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_rear_fender.geometry} material={nodes.body_rear_fender.material} />
			<mesh castShadow receiveShadow geometry={nodes.Plane036.geometry} material={nodes.Plane036.material} />
			<mesh castShadow receiveShadow geometry={nodes.Plane036_1.geometry} material={nodes.Plane036_1.material} />
			<mesh castShadow receiveShadow geometry={nodes.Plane020.geometry} material={nodes.Plane020.material} />
			<mesh castShadow receiveShadow geometry={nodes.Plane020_1.geometry} material={nodes.Plane020_1.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_sill.geometry} material={nodes.body_sill.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_spoiler_side_plates.geometry} material={nodes.body_spoiler_side_plates.material} />
			<mesh castShadow receiveShadow geometry={nodes.body_spoiler_wing.geometry} material={nodes.body_spoiler_wing.material} />
			<group position={[0, -0.05, 0]}>
				<mesh castShadow receiveShadow geometry={nodes.Plane014.geometry} material={nodes.Plane014.material} />
				<mesh castShadow receiveShadow geometry={nodes.Plane014_1.geometry} material={nodes.Plane014_1.material} />
			</group>
			<mesh castShadow receiveShadow geometry={nodes.door_L.geometry} material={nodes.door_L.material} />
			<mesh castShadow receiveShadow geometry={nodes.door_R.geometry} material={nodes.door_R.material} />
			<group position={[0, -0.05, 0]}>
				<mesh castShadow receiveShadow geometry={nodes.Plane018.geometry} material={nodes.Plane018.material} />
				<mesh castShadow receiveShadow geometry={nodes.Plane018_1.geometry} material={nodes.Plane018_1.material} />
			</group>
			<mesh castShadow receiveShadow geometry={nodes.roombox.geometry} material={nodes.roombox.material} />
			<mesh castShadow receiveShadow geometry={nodes.door_glas_mirror_L.geometry} material={nodes.door_glas_mirror_L.material} />
			<mesh castShadow receiveShadow geometry={nodes.door_glas_mirror_R.geometry} material={nodes.door_glas_mirror_R.material} />
			<mesh castShadow receiveShadow geometry={nodes.door_glas_side_L.geometry} material={nodes.door_glas_side_L.material} />
			<mesh castShadow receiveShadow geometry={nodes.door_glas_side_R.geometry} material={nodes.door_glas_side_R.material} />
			<mesh castShadow receiveShadow geometry={nodes.glas_headlight_main.geometry} material={nodes.glas_headlight_main.material} />
			<mesh castShadow receiveShadow geometry={nodes.glas_headlight_main001.geometry} material={nodes.glas_headlight_main001.material} />
			<mesh castShadow receiveShadow geometry={nodes.glas_taillight_main.geometry} material={nodes.glas_taillight_main.material} />
			<mesh castShadow receiveShadow geometry={nodes.glas_taillight_main001.geometry} material={nodes.glas_taillight_main001.material} />
			<mesh castShadow receiveShadow geometry={nodes.glas_window_front.geometry} material={nodes.glas_window_front.material} />
			<mesh castShadow receiveShadow geometry={nodes.grill_front_intake_poly.geometry} material={nodes.grill_front_intake_poly.material} position={[0, -0.05, 0]} />
			<mesh castShadow receiveShadow geometry={nodes.grill_front_intake_side_poly.geometry} material={nodes.grill_front_intake_side_poly.material} position={[0, -0.05, 0]} />
			<mesh castShadow receiveShadow geometry={nodes.grill_rear_frame.geometry} material={nodes.grill_rear_frame.material} />
			<mesh castShadow receiveShadow geometry={nodes.grill_rear_poly.geometry} material={nodes.grill_rear_poly.material} />
			<mesh castShadow receiveShadow geometry={nodes.grill_side_poly.geometry} material={nodes.grill_side_poly.material} />
			<mesh castShadow receiveShadow geometry={nodes.headlight_box.geometry} material={nodes.headlight_box.material} />
			<group rotation={[0.16, 0, 0]}>
				<mesh castShadow receiveShadow geometry={nodes.Plane202.geometry} material={nodes.Plane202.material} />
				<mesh castShadow receiveShadow geometry={nodes.Plane202_1.geometry} material={materials['glas red taillight']} />
			</group>
			<mesh castShadow receiveShadow geometry={nodes.boolcut_front_bumper_middle001.geometry} material={nodes.boolcut_front_bumper_middle001.material} />
			<mesh castShadow receiveShadow geometry={nodes.boolcut_side_win_screw001.geometry} material={nodes.boolcut_side_win_screw001.material} />
			<group position={[-0.28, 0.5, -2.18]} rotation={[-0.03, Math.PI / 2, 0]} scale={0.76}>
				<mesh castShadow receiveShadow geometry={nodes.Plane208.geometry} material={materials['exhaust outside metal scratched']} />
				<mesh castShadow receiveShadow geometry={nodes.Plane208_1.geometry} material={materials['exhaust inside dark']} />
			</group>
			<mesh castShadow receiveShadow geometry={nodes.overroll.geometry} material={nodes.overroll.material} />
			<group position={[0, 1.09, -0.21]} rotation={[-0.11, 0, 0]} scale={[0.5, 0.5, 0.5]}>
				<mesh castShadow receiveShadow geometry={nodes.Circle092.geometry} material={nodes.Circle092.material} />
				<mesh castShadow receiveShadow geometry={nodes.Circle092_1.geometry} material={nodes.Circle092_1.material} />
			</group>
			<mesh castShadow receiveShadow geometry={nodes.part_bottom_plate.geometry} material={nodes.part_bottom_plate.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_bumper_front_intake_cooler.geometry} material={nodes.part_bumper_front_intake_cooler.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_engine_box.geometry} material={materials['plastic structure dark']} />
			<mesh castShadow receiveShadow geometry={nodes.part_front_cooler.geometry} material={materials['front cooler metal ']} rotation={[0.72, 0, 0]} />
			<mesh castShadow receiveShadow geometry={nodes.part_front_intakes_frames.geometry} material={nodes.part_front_intakes_frames.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_front_window_joined_hood.geometry} material={nodes.part_front_window_joined_hood.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_front_window_rubber_frame.geometry} material={nodes.part_front_window_rubber_frame.material} />
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.part_fuel_R.geometry}
				material={nodes.part_fuel_R.material}
				position={[-0.74, 0.79, -0.82]}
				rotation={[0.05, 0.03, 0.53]}
				scale={[0.48, 0.48, 0.48]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.part_fuel_R001.geometry}
				material={nodes.part_fuel_R001.material}
				position={[-0.74, 0.79, -0.82]}
				rotation={[0.05, 0.03, 0.53]}
				scale={[0.48, 0.48, 0.48]}
			/>
			<mesh castShadow receiveShadow geometry={nodes.part_hood_intakes.geometry} material={nodes.part_hood_intakes.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_hood_sick.geometry} material={nodes.part_hood_sick.material} />
			<group position={[0, 0.41, 2.13]} rotation={[-0.93, 0, 0]} scale={0.26}>
				<mesh castShadow receiveShadow geometry={nodes.layer1.geometry} material={materials['Lambo logo gold frame']} />
				<mesh castShadow receiveShadow geometry={nodes.layer1_1.geometry} material={materials['Lambo logo black metal']} />
				<mesh castShadow receiveShadow geometry={nodes.layer1_2.geometry} material={materials['Lambo logo gold bull']} />
				<mesh castShadow receiveShadow geometry={nodes.layer1_3.geometry} material={materials['Lambo logo gold dark bull']} />
				<mesh castShadow receiveShadow geometry={nodes.layer1_4.geometry} material={materials['Lambo logo gold bull light']} />
			</group>
			<mesh castShadow receiveShadow geometry={nodes.part_rear_bumper_holebox.geometry} material={nodes.part_rear_bumper_holebox.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_rear_closecap.geometry} material={nodes.part_rear_closecap.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_rear_diffusor_holders.geometry} material={nodes.part_rear_diffusor_holders.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_rear_hole.geometry} material={nodes.part_rear_hole.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_rear_side_win_frame_rubber.geometry} material={nodes.part_rear_side_win_frame_rubber.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_splitter_side_woody.geometry} material={materials.wood} />
			<mesh castShadow receiveShadow geometry={nodes.part_spoiler_wing_angle.geometry} material={nodes.part_spoiler_wing_angle.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_tow_ribbon_front.geometry} material={materials['cloth towing red fabric ']} />
			<mesh castShadow receiveShadow geometry={nodes.part_wheelbase_front.geometry} material={nodes.part_wheelbase_front.material} />
			<mesh castShadow receiveShadow geometry={nodes.part_wheelbase_rear.geometry} material={nodes.part_wheelbase_rear.material} />
			<group position={[0.01, 0.68, 1.4]} rotation={[-1.12, 0.03, -0.35]} scale={[0.74, 0.74, 0.74]}>
				<mesh castShadow receiveShadow geometry={nodes.Plane058.geometry} material={nodes.Plane058.material} />
				<mesh castShadow receiveShadow geometry={nodes.Plane058_1.geometry} material={nodes.Plane058_1.material} />
				<mesh castShadow receiveShadow geometry={nodes.Plane058_2.geometry} material={nodes.Plane058_2.material} />
			</group>
			<mesh castShadow receiveShadow geometry={nodes.seat.geometry} material={nodes.seat.material} />
			<mesh castShadow receiveShadow geometry={nodes.side_win_frame_L.geometry} material={nodes.side_win_frame_L.material} position={[0, -0.05, 0]} />
			<mesh castShadow receiveShadow geometry={nodes.side_win_frame_R.geometry} material={nodes.side_win_frame_R.material} position={[0, -0.05, 0]} />
			<mesh castShadow receiveShadow geometry={nodes.side_win_L.geometry} material={nodes.side_win_L.material} position={[0, -0.05, 0]} />
			<mesh castShadow receiveShadow geometry={nodes.side_win_R.geometry} material={nodes.side_win_R.material} position={[0, -0.05, 0]} />
			<group position={[0.9, 0.3, 1.22]}>
				<group position={[-0.03, 0, 0]} scale={0.46}>
					<mesh castShadow receiveShadow geometry={nodes.Plane010.geometry} material={nodes.Plane010.material} />
					<mesh castShadow receiveShadow geometry={nodes.Plane010_1.geometry} material={nodes.Plane010_1.material} />
					<mesh castShadow receiveShadow geometry={nodes.Plane010_2.geometry} material={nodes.Plane010_2.material} />
				</group>
				<mesh castShadow receiveShadow geometry={nodes.wheel_FL_rim_outside_anim.geometry} material={nodes.wheel_FL_rim_outside_anim.material} rotation={[0.74, 0, 0]}>
					<mesh castShadow receiveShadow geometry={nodes.wheel_FL_brakedisc.geometry} material={nodes.wheel_FL_brakedisc.material} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_FL_brakedisc001.geometry} material={nodes.wheel_FL_brakedisc001.material} position={[0, -1.76, -1.98]} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_FL_hinge.geometry} material={nodes.wheel_FL_hinge.material} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_FL_rim_inside.geometry} material={nodes.wheel_FL_rim_inside.material} position={[0.1, 0, 0]} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_FL_tire.geometry} material={nodes.wheel_FL_tire.material} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_FL_zv.geometry} material={nodes.wheel_FL_zv.material} />
				</mesh>
			</group>
			<group position={[-0.9, 0.3, 1.22]}>
				<mesh castShadow receiveShadow geometry={nodes.wheel_FR_rim_outside.geometry} material={nodes.wheel_FR_rim_outside.material}>
					<mesh castShadow receiveShadow geometry={nodes.wheel_FR_hinge.geometry} material={nodes.wheel_FR_hinge.material} rotation={[0, 0, 0.05]} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_FR_rim_inside.geometry} material={nodes.wheel_FR_rim_inside.material} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_FR_tire.geometry} material={nodes.wheel_FR_tire.material} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_FR_zv.geometry} material={nodes.wheel_FR_zv.material} />
				</mesh>
			</group>
			<group position={[0.9, 0.32, -1.44]} rotation={[0, 0, 0.05]}>
				<group position={[-0.03, 0, 0]} scale={0.46}>
					<mesh castShadow receiveShadow geometry={nodes.Plane015.geometry} material={nodes.Plane015.material} />
					<mesh castShadow receiveShadow geometry={nodes.Plane015_1.geometry} material={nodes.Plane015_1.material} />
					<mesh castShadow receiveShadow geometry={nodes.Plane015_2.geometry} material={nodes.Plane015_2.material} />
				</group>
				<mesh castShadow receiveShadow geometry={nodes.wheel_RL_rim_outside.geometry} material={nodes.wheel_RL_rim_outside.material} rotation={[-0.19, 0, 0]}>
					<mesh castShadow receiveShadow geometry={nodes.wheel_RL_hinge.geometry} material={nodes.wheel_RL_hinge.material} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_RL_rim_inside.geometry} material={nodes.wheel_RL_rim_inside.material} position={[0.1, 0, 0]} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_RL_Tire.geometry} material={nodes.wheel_RL_Tire.material} position={[0.1, 0, 0]} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_RL_zv.geometry} material={nodes.wheel_RL_zv.material} />
				</mesh>
			</group>
			<group position={[-0.9, 0.32, -1.44]}>
				<mesh castShadow receiveShadow geometry={nodes.wheel_RR_rim_outside.geometry} material={nodes.wheel_RR_rim_outside.material}>
					<mesh castShadow receiveShadow geometry={nodes.wheel_RR_hinge.geometry} material={nodes.wheel_RR_hinge.material} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_RR_rim_inside.geometry} material={nodes.wheel_RR_rim_inside.material} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_RR_Tire.geometry} material={nodes.wheel_RR_Tire.material} />
					<mesh castShadow receiveShadow geometry={nodes.wheel_RR_zv.geometry} material={nodes.wheel_RR_zv.material} />
				</mesh>
			</group>
		</group>
	)
}

useGLTF.preload('/car/model.gltf')
